import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// components
import Root from './Root';
import PrivateRoute from './PrivateRoute';

// lazy load all the views
// auth


// home
const Home = React.lazy(() => import('../pages/Home'));
const Mhub = React.lazy(() => import('../pages/Mhub'));
const Profile = React.lazy(() => import('../pages/Company'));
const Contact = React.lazy(() => import('../pages/Contact'));
const Projects = React.lazy(() => import('../pages/Projects'));
const Electrical = React.lazy(() => import('../pages/Service/Electrical'));
const Solar = React.lazy(() => import('../pages/Service/Solar'));
const ICT = React.lazy(() => import('../pages/Service/ICT'));
const Maintenance = React.lazy(() => import('../pages/Service/Maintenance'));
const Blog = React.lazy(() => import('../pages/Blog'));
const Privacy = React.lazy(() => import('../pages/Privacy'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            // root route
            path: '/',
            element: <Root />,
        },
        {
            // public routes
            path: '/',
            children: [
                {
                    path: 'home',
                    element: <LoadComponent component={Home} />,
                },
                {
                    path: 'm-hub',
                    element: <LoadComponent component={Mhub} />,
                },
                {
                    path: 'contact',
                    element: <LoadComponent component={Contact} />,
                },
                {
                    path: 'profile',
                    element: <LoadComponent component={Profile} />,
                },
                {
                    path: 'projects',
                    element: <LoadComponent component={Projects} />,
                },
                {
                    path: 'electrical-services',
                    element: <LoadComponent component={Electrical} />,
                },
                {
                    path: 'maintenance-services',
                    element: <LoadComponent component={Maintenance} />,
                },
                {
                    path: 'solar-services',
                    element: <LoadComponent component={Solar} />,
                },
                {
                    path: 'ict-services',
                    element: <LoadComponent component={ICT} />,
                },
                {
                    path: 'news',
                    element: <LoadComponent component={Blog} />,
                },
                {
                    path: 'privacy',
                    element: <LoadComponent component={Privacy} />,
                },
            ],
        },
    ]);
};

export default AllRoutes;
